<template>
  <div class="minePage">
    <div class="mineMain">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="contentBox">
          <div class="hearBox">
            <div class="topBox">
              <div class="avatarBox">
                <div class="avatarBorder">
                  <ImgDecypt
                    class="avatarImg"
                    :src="userInfo.portrait"
                    :key="userInfo.portrait"
                    round
                  />
                </div>
                <div @click="toEditInfo">
                  <svg-icon class="editIcon" iconClass="editIcon" />
                </div>
              </div>
              <router-link
                tag="div"
                :to="`/userHomepage?uid=${userInfo.uid || 0}`"
                class="homePagebtn"
              ></router-link>
            </div>
            <div class="nameAndLabel">
              <div class="userName">{{ userInfo.name }}</div>
              <svg-icon
                class="vipIcon"
                iconClass="vipIcon"
                v-if="userInfo.isVip"
              />
              <svg-icon
                class="userIcon"
                iconClass="mercIcon"
                v-if="checkTime(userInfo.officialCertExpire)"
              />
              <svg-icon
                class="userIcon"
                iconClass="bloggerIcon"
                v-if="checkTime(userInfo.superUserExpire)"
              />
              <svg-icon
                class="userIcon"
                iconClass="supremeIcon"
                v-if="checkTime(userInfo.videoFreeExpiration)"
              />
            </div>
            <div class="userInfo">
              <div class="userId">涩漫ID：{{ userInfo.uid }}</div>
              <div class="smallVerticalLine"></div>
              <div>{{ userInfo.age || 0 }}岁</div>
              <div class="smallVerticalLine" v-if="userInfo.region"></div>
              <div class="city">{{ userInfo.region }}</div>
              <div class="smallVerticalLine" v-if="userInfo.gender"></div>
              <svg-icon
                class="sexIcon"
                iconClass="maleIcon"
                v-if="userInfo.gender == 'male'"
              />
              <svg-icon
                class="sexIcon"
                iconClass="womanIcon"
                v-if="userInfo.gender == 'female'"
              />
            </div>
            <div class="vipBox">
              <div
                class="diamondIcon"
                v-if="!userInfo.isVip && !countdownSec"
              />
              <div class="vipDiamondIcon" v-else />
              <div
                class="vipText"
                :class="{
                  isVipText:
                    userInfo.isVip ||
                    checkTime(userInfo.videoFreeExpiration) ||
                    checkTime(userInfo.vipExpireDate),
                }"
              >
                <div class="vipBoxTitle">
                  {{
                    userInfo.vipName
                      ? userInfo.vipName
                      : countdownSec
                      ? "新人限时特惠 畅享全场"
                      : "VIP限时特惠 畅享全场"
                  }}
                </div>
                <!-- <div class="vipBoxTitle"></div> -->
                <div v-if="userInfo.isVip" class="openVipText">
                  {{ handleVipText() }}
                </div>
                <div class="timer" v-else-if="countdownSec">
                  倒计时
                  <div class="timeItem">{{ hr }}</div>
                  :
                  <div class="timeItem">{{ min }}</div>
                  :
                  <div class="timeItem">{{ sec }}</div>
                </div>
                <div v-else-if="!userInfo.isVip" class="openVipText">
                  {{ handleVipText() }}
                </div>
              </div>
              <router-link
                tag="div"
                to="/rechargePage?t=vip"
                class="openVip"
                :class="{
                  renewVip:
                    userInfo.isVip || checkTime(userInfo.videoFreeExpiration),
                }"
                v-if="userInfo.isVip || checkTime(userInfo.videoFreeExpiration)"
              ></router-link>
              <router-link
                tag="div"
                to="/rechargePage?t=vip"
                class="redOpenVip"
                v-else-if="countdownSec"
              ></router-link>
              <router-link
                tag="div"
                to="/rechargePage?t=vip"
                class="openVip"
                :class="{
                  renewVip:
                    userInfo.isVip || checkTime(userInfo.videoFreeExpiration),
                }"
                v-else-if="!userInfo.isVip"
              ></router-link>
            </div>
          </div>
          <div class="main">
            <!-- <div
              class="signIn"
              v-if="userInfo.whoringCard && userInfo.whoringCard.hasWhoringCard"
              @click="$router.push('/signIn')"
            >
              <div class="signIn_top flex-center-between">
                <div class="signIn_left">
                  连续登录{{ currentSignDays }}天
                  <span v-if="forgetSignDays"
                    >/已漏打卡{{ forgetSignDays }}天</span
                  >
                  <span v-else>/{{ totalDays }}天</span>
                </div>
                <div class="signIn_right ckeckBtn" v-if="forgetSignDays">
                  点击查看
                </div>
                <div class="signIn_right" v-else>完成任务送等额金币</div>
              </div>
              <div class="signIn_bottom flex-center-center">
                <van-progress
                  class="progress"
                  :percentage="percentage"
                  stroke-width="5"
                  :show-pivot="false"
                  :color="forgetSignDays ? '#808080' : '#94D6DA'"
                />
              </div>
            </div> -->
            <div class="assetsBox">
              <div class="assetsItem goldBg" @click="toRecharge">
                <div class="assetsInfo">
                  <div class="assetsTitle">金币</div>
                  <div class="assetsNum">
                    {{ walletInfo.amount + walletInfo.income || 0 }}
                  </div>
                </div>
                <div class="goldenBeans"></div>
              </div>
              <div class="assetsItem couponBg" @click="showCouponNum">
                <div class="assetsInfo">
                  <div class="assetsTitle">观阅券</div>
                  <div class="assetsNum">
                    {{ (couponNum || 0) | watchCount }}
                  </div>
                </div>
                <div class="couponIcon"></div>
              </div>
            </div>
            <div class="signBox" @click="sign">
              <div class="signTip"></div>
            </div>
            <!-- 5宫格广告 -->
            <div class="fiveGrid" v-if="advRowList.length">
              <div
                class="rectAdvBox"
                @click="jumpAdv(item, 'ads')"
                v-for="item in advRowList"
                :key="item.id"
              >
                <LazyImg class="rectAdvIcon" :src="item.cover" />
                <div class="rectName">{{ item.title }}</div>
              </div>
            </div>
            <div class="subTitle">常用功能</div>

            <div class="optionsBox">
              <div
                class="optItem"
                v-for="item in optList"
                :key="item.icon"
                @click="jumpPage(item.path)"
              >
                <svg-icon class="optIcon" :iconClass="item.icon" />
                <div class="optName">{{ item.name }}</div>
              </div>
            </div>
            <div class="cellBox">
              <router-link tag="div" to="/expensesRecord" class="rowItem">
                <svg-icon class="rowIcon" iconClass="expensesRecord" />
                <div class="rowName">消费记录</div>
                <svg-icon class="rightArrow" iconClass="rightArrow" />
              </router-link>
              <router-link tag="div" to="/revenueCenter" class="rowItem">
                <svg-icon class="rowIcon" iconClass="revenueCenter" />
                <div class="rowName">收益中心</div>
                <svg-icon class="rightArrow" iconClass="rightArrow" />
              </router-link>
              <router-link tag="div" to="/loufeng" class="rowItem">
                <svg-icon class="rowIcon" iconClass="applicationCenter" />
                <div class="rowName">应用中心</div>
                <svg-icon class="rightArrow" iconClass="rightArrow" />
              </router-link>
              <router-link tag="div" to="/myMsg" class="rowItem">
                <svg-icon class="rowIcon" iconClass="myMsessage" />
                <div class="rowName msgTip">
                  我的消息
                  <div
                    class="redDot"
                    v-if="
                      this.msgHasNew.comment ||
                      this.msgHasNew.official ||
                      this.msgHasNew.reward ||
                      this.msgHasNew.thumbs
                    "
                  ></div>
                </div>
                <svg-icon class="rightArrow" iconClass="rightArrow" />
              </router-link>
              <router-link tag="div" to="/businessCooperation" class="rowItem">
                <svg-icon class="rowIcon" iconClass="busCooperation" />
                <div class="rowName">商务合作</div>
                <svg-icon class="rightArrow" iconClass="rightArrow" />
              </router-link>
              <router-link tag="div" to="/officialGround" class="rowItem">
                <svg-icon class="rowIcon" iconClass="officialCommunity" />
                <div class="rowName">官方社群</div>
                <svg-icon class="rightArrow" iconClass="rightArrow" />
              </router-link>
              <router-link tag="div" to="/setting" class="rowItem">
                <svg-icon class="rowIcon" iconClass="settingsCenter" />
                <div class="rowName">设置中心</div>
                <svg-icon class="rightArrow" iconClass="rightArrow" />
              </router-link>
            </div>
            <div class="sharePromotion" @click="$router.push('sharePromotion')">
              <div class="invitationCode">
                我的邀请码 {{ userInfo.promotionCode }}
              </div>
              <div class="officialUrl mb80">
                永久官方地址 {{ userInfo.promoteURL }}
              </div>
            </div>
          </div>
        </div>
      </van-pull-refresh>
    </div>

    <van-popup v-model="showSignPop" class="signPop" @close="closeSignPop">
      <div class="signBox">
        <div class="signSuc">签到成功</div>
        <div class="sginDate">已累计签到{{ signCount }}天</div>
        <div class="dateBox">
          <div class="dateItem" v-for="date in 7" :key="date">
            <div class="date" :class="{ signedBg: date <= signCount }">
              <svg-icon
                class="couponSignDate"
                iconClass="couponSignDate"
                v-if="date == 2 || date == 7"
              />
              <svg-icon
                class="signed"
                iconClass="signed"
                v-else-if="date <= signCount"
              />
              <div v-else>{{ date }}</div>
              <div class="dateCoponNum" v-if="date == 2 || date == 7">x1</div>
            </div>
            <div class="line" v-if="date != 7"></div>
          </div>
        </div>
        <div class="signContentBox">
          <div class="awardTitle">
            累计签到{{ finishCondition }}天奖励观阅券
          </div>
          <div class="awardBox">
            <svg-icon class="signAward" iconClass="signAward" />x{{
              signWatchCount
            }}
          </div>
          <div class="signBtn" @click="showSignPop = false">今日已签到</div>
          <div class="signTip">累计7天/周期 循环签到</div>
        </div>
      </div>
    </van-popup>
    <div class="aiUndress" @click="$router.push('/aiUndress')"></div>
    <!-- <van-popup v-model="certificatePop" class="certificatePop" @close="closeCertificatePop">
      <div class="certificateBox">
        <div class="certificateTitle">账号凭证</div>
        <div class="certificateContent">
          受行业限制，APP无法正常使用时需升级，未绑定手机号码会导致账号信息丢失。请及时绑定手机号码或保存账号凭证，以免VIP信息丢失，造成巨大财产损失！账号丢失的用户可在 『我的』页面-账号找回，原账号的VIP信息会转移至新账号上。
        </div>
        <div class="bindPhoneBox">
          <div class="bindPhoneText" @click="bindPhone">绑定手机号</div>
          <svg-icon class="rightAttow" iconClass="settingArrow" />
        </div>
        <div class="popLine"></div>
        <div class="saveBtn" @click="saveCredentials">保存账号凭证</div>
      </div>
    </van-popup> -->
  </div>
</template>

<script>
import { queryUserInfo, queryRights, sign } from "@/api/user";
import { PullRefresh, Toast, Progress } from "vant";
import { setSessionItem } from "@/utils/longStorage";
import { queryTiroCountdown } from "@/api/mine";
import ImgDecypt from "@/components/ImgDecypt";
import { getAdItem, AdType, jumpAdv } from "@/utils/getConfig";
import LazyImg from "@/components/LazyImg";
export default {
  components: {
    ImgDecypt,
    [Progress.name]: Progress,
    [PullRefresh.name]: PullRefresh,
    LazyImg,
  },
  data() {
    return {
      msgHasNew: {},
      currentUser: {}, // 当前用户
      userInfo: {}, // 用户信息
      backgroundImg: "", // 背景
      walletInfo: {}, // 钱包信息
      couponNum: 0, // 观阅券数量
      isLoading: false,
      showSignPop: false, // 是否显示签到弹窗
      finishCondition: 0, // 累计签到天数
      signCount: 0, // 当前签到天数
      signWatchCount: 0, // 累计签到奖励
      certificatePop:
        sessionStorage.getItem("isCertificate") == 0 ? false : true, // 账号凭证
      percentage: 0, // 打卡进度
      totalDays: 0, // 需要打卡天数
      currentSignDays: 0, // 连续打卡天数
      forgetSignDays: 0, // 忘记打卡天数
      countdownSec: 0,
      hr: "",
      min: "",
      sec: "",
      optList: [
        {
          name: "创作中心",
          icon: "creationCenter",
          path: "creationCenter",
        },
        {
          name: "历史记录",
          icon: "historyRecord",
          path: "historyRecord",
        },
        {
          name: "已购作品",
          icon: "purchased",
          path: "purchased",
        },
        {
          name: "我的收藏",
          icon: "collect",
          path: "collect",
        },
        {
          name: "分享推广",
          icon: "sharePromotion",
          path: "sharePromotion",
        },
        {
          name: "领取兑换",
          icon: "exchangeCode",
          path: "/exchangeCode",
        },
        {
          name: "账号凭证",
          icon: "actCredentials",
          path: "/accountCredentials",
        },
        {
          name: "在线客服",
          icon: "customerService",
          path: "customerService",
        },
      ],
    };
  },
  created() {
    this.currentUser = JSON.parse(sessionStorage.getItem("userInfo"));
    this.queryTiroCountdown();
    this.queryUserInfo();
    this.queryBalance();
    this.queryMsgHasNew();
    this.queryRights();
    this.advRowList = (getAdItem(AdType.MINEGRID) || []).slice(0, 10);
  },
  methods: {
    jumpAdv: jumpAdv,
    // 查询新手优惠时间
    async queryTiroCountdown() {
      let res = await this.$Api(queryTiroCountdown);
      const { data } = res;
      if (res && res.code === 200) {
        this.countdownSec = data.countdownSec;
        console.log(res);
        this.countdown(this.countdownSec);
      }
    },
    // 倒计时
    countdown(timer) {
      if (timer <= 0) return;
      // 相差的毫秒数
      const msec = timer;
      // 计算时分秒数
      let hr = parseInt(msec / 60 / 60);
      let min = parseInt((msec / 60) % 60);
      let sec = parseInt(msec % 60);
      // 个位数前补零
      this.hr = hr > 9 ? hr : "0" + hr;
      this.min = min > 9 ? min : "0" + min;
      this.sec = sec > 9 ? sec : "0" + sec;
      // this.timeStr = `${hr}:${min}:${sec}`;
      // 控制台打印
      // console.log(` ${hr}小时 ${min}分钟 ${sec}秒`)
      // 一秒后递归
      this.countdownFncs = setTimeout(() => {
        timer--;
        this.countdown(timer);
      }, 1000);
    },
    // 刷新
    async onRefresh() {
      // this.queryUserInfo();
      // this.queryBalance();
      // this.queryRights();
      Promise.all([
        this.queryUserInfo(),
        this.queryBalance(),
        this.queryMsgHasNew(),
        this.queryRights(),
      ])
        .then((e) => {
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          Toast("请求失败清稍后再试");
        });
      // console.log(axios.all())
      // axios.all([this.queryUserInfo(), this.queryBalance(), this.queryRights()])
      // .then(axios.spread(function (acct, perms) {
      //   console.log(12312312313)
      //   this.isLoading = false
      // }))
      // this.isLoading = false
    },
    // 查询用户信息
    async queryUserInfo() {
      // let req = this.$route.query.uid ? {
      //   uid: this.$route.query.uid != this.$store.getters.userInfo.uid ? this.$route.query.uid : 0
      // } : {
      //   uid: 0
      // };
      let res = await this.$Api(queryUserInfo);
      if (res.code === 200) {
        this.userInfo = res.data;
        this.totalDays =
          this.userInfo?.whoringCard?.detailInfo[0]?.totalDays || 0;
        this.currentSignDays =
          this.userInfo?.whoringCard?.detailInfo[0]?.currentSignDays || 0;
        this.forgetSignDays =
          this.userInfo?.whoringCard?.detailInfo[0]?.forgetSignDays || 0;
        this.percentage = (this.currentSignDays / this.totalDays) * 100;
        this.backgroundImg = this.userInfo.background
          ? this.userInfo.background[0]
          : "";
        setSessionItem("userInfo", JSON.stringify(res.data));
        this.$store.commit("user/SET_USERINFO", res.data);
        // this.$store.dispatch("commit/SET_USERINFO", res.data);
      }
    },

    jumpPage(path) {
      if (path) {
        this.$router.push(path);
      }
    },

    // 校验有效时间
    checkTime(timer) {
      let todayTime = new Date().getTime();
      let effectiveTime = new Date(timer).getTime();
      if (timer && todayTime < effectiveTime) {
        return true;
      }
      return false;
    },

    // 处理会员时间展示
    handleVipText() {
      let str = "开通会员畅享更多会员特权";
      if (this.checkTime(this.userInfo.videoFreeExpiration)) {
        let dateago = new Date(this.userInfo.vipExpireDate);
        let y = dateago.getFullYear();
        let m = dateago.getMonth() + 1;
        let d = dateago.getDate();
        str = `到期时间 ${y + "-" + m + "-" + d}`;
      } else if (this.checkTime(this.userInfo.vipExpireDate)) {
        let dateago = new Date(this.userInfo.vipExpireDate);
        let y = dateago.getFullYear();
        let m = dateago.getMonth() + 1;
        let d = dateago.getDate();
        str = `到期时间 ${y + "-" + m + "-" + d}`;
      }
      return str;
    },
    // 消息红点
    async queryMsgHasNew() {
      await this.$store.dispatch("user/updateMsgHasNew");
      this.msgHasNew = this.$store.getters.msgHasNew;
    },
    // 查询钱包
    async queryBalance() {
      await this.$store.dispatch("user/getWallet");
      this.walletInfo = this.$store.getters.walletInfo;
    },
    // 查询权益
    async queryRights() {
      let res = await this.$Api(queryRights);
      if (res && res.code == 200) {
        let couponList = res.data.watchReadCoupon || [];
        this.couponNum = couponList.length;
      }
    },
    // 前往编辑资料
    toEditInfo() {
      this.$router.push("/editInfo");
    },
    toRecharge() {
      this.$router.push("/rechargePage?t=gold");
    },
    showCouponNum() {
      Toast(`您拥有${this.couponNum}张观阅券`);
    },
    sign() {
      this.$router.push("/sign");
      // try {
      //   this.$store.commit("app/SET_LOADING", true);
      //   let res = await this.$Api(sign);
      //   this.$store.commit("app/SET_LOADING", false);
      //   if (res && res.code == 200) {
      //     this.finishCondition = res.data.finishCondition;
      //     this.signCount = res.data.signCount;
      //     // this.signCount = 2;
      //     this.signWatchCount = res.data.watchCount;
      //     this.showSignPop = true;
      //   } else {
      //     Toast(res.tip || "签到失败");
      //   }
      // } catch (error) {
      //   this.$store.commit("app/SET_LOADING", false);
      //   Toast("签到失败");
      // }
    },
    closeSignPop() {
      this.finishCondition = 0;
      this.signCount = 0;
      this.signWatchCount = 0;
    },
    closeCertificatePop() {
      sessionStorage.setItem("isCertificate", "0");
    },
    bindPhone() {
      this.certificatePop = false;
      this.$router.push("/bindPhone");
    },
    saveCredentials() {
      this.certificatePop = false;
      this.$router.push("/accountCredentials");
    },
  },
};
</script>

<style lang="scss" scoped>
.minePage {
  color: #000;
  background: #f2f2f2;
  height: 100%;
  // height: 100vh;
  // overflow-y: auto;
  .mineMain {
    height: 100%;
    overflow-y: auto;
  }

  .contentBox {
    // height: 100vh;
    // overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .hearBox {
    width: 100%;
    // height: 187px;
    background: url("../../assets/png/gradientBg.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding: 12px 16px 0;
    box-sizing: border-box;
    margin-bottom: 10px;

    .backgroundMask {
      height: 150px;
      width: 100%;
      background-image: linear-gradient(
        180deg,
        #ffffff00 0%,
        #130f3270 50%,
        #130f32 100%
      );
      position: absolute;
      bottom: 0;
      z-index: 1;
    }
    .topBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .homePagebtn {
      width: 65px;
      height: 33px;
      background: url("../../assets/png/homePageBtn.png") no-repeat;
      background-size: 100% 100%;
    }

    .avatarBox {
      // position: absolute;
      // bottom: 0px;
      // left: 12px;
      // z-index: 3;
      display: flex;
      align-items: flex-end;

      .avatarBorder {
        width: 60px;
        height: 60px;
        // padding: 5px;
        border-radius: 50%;
        box-sizing: border-box;
        // background: #1d164c;
        overflow: hidden;

        .avatarImg {
          width: 100%;
          height: 100%;

          /deep/ .vanImage {
            background: #00000000 !important;
          }

          /deep/ .van-image__error {
            background-color: #130f3200 !important;
          }
        }
      }

      .editIcon {
        width: 20px;
        height: 20px;
      }
    }
    .nameAndLabel {
      height: 25px;
      margin-top: 18px;
      display: flex;
      align-items: center;

      .userName {
        font-size: 18px;
        margin-right: 12px;
      }

      .vipIcon {
        width: 28px;
        height: 15px;
        margin-right: 4px;
      }

      .userIcon {
        width: 42px;
        height: 15px;
        margin-right: 4px;
      }
    }
    .userInfo {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-top: 12px;
      color: #999999;

      .sexIcon {
        width: 16px;
        height: 16px;
        margin-right: 3px;
      }
    }
    .smallVerticalLine {
      width: 1px;
      height: 6px;
      margin: 0 6px;
      background: #423f5e;
    }
    .vipBox {
      width: 100%;
      height: 56px;
      margin: 36px 0 9px;
      padding-right: 20px;
      // padding-left: 18px;
      border-radius: 8px;
      box-sizing: border-box;
      // background: #94d6da;
      background: url("../../assets/png/mineVipBoxBg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      .diamondIcon {
        width: 60px;
        height: 75px;
        background: url("../../assets/png/diamondIcon.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: -24px;
        left: 12px;
        // margin-right: 36.6px;
      }
      .vipDiamondIcon {
        width: 60px;
        height: 75px;
        background: url("../../assets/png/vipDiamondIcon.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: -24px;
        left: 12px;
        // margin-right: 36.6px;
      }

      .vipText {
        flex: 1;
        // font-size: 14px;
        // margin: 0 8px;
        // color: #fff;
        margin-left: 80px;
        font-size: 10px;
        color: rgba(255, 255, 255, 0.7);
        .vipBoxTitle {
          font-size: 14px;
          background: linear-gradient(91deg, #fcf6e9 0%, #edd09b 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .timer {
          display: flex;
          align-items: center;
          .timeItem {
            width: 20px;
            height: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
            background: #d54941;
            margin: 0 2px;
            color: #fff;
          }
          .timeItem:first-child {
            margin-right: 6px;
          }
        }
      }
      // .openVipText {
      //   font-size: 12px;
      // }

      .isVipText {
        // margin: 0 8px;
        // color: #ffd858;
      }

      .openVip {
        width: 77px;
        height: 24px;
        background: url("../../assets/png/openVipBtn.png") no-repeat;
        background-size: 100% 100%;
      }

      .renewVip {
        width: 77px;
        height: 24px;
        background: url("../../assets/png/renewVipBtn.png") no-repeat;
        background-size: 100% 100%;
      }
      .redOpenVip {
        width: 77px;
        height: 24px;
        background: url("../../assets/png/redOpenVip.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .main {
    padding: 0 16px;
    box-sizing: border-box;
    .signBox {
      width: 100%;
      height: 50px;
      background-image: url("../../assets/png/signBox.png");
      background-size: 100% 100%;
      margin-top: 12px;
      position: relative;
      .signTip {
        position: absolute;
        top: 11px;
        right: 7px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #fa4974;
      }
    }
    .subTitle {
      font-size: 16px;
      font-weight: 500;
      margin-top: 12px;
    }

    .assetsBox {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 9px;

      .assetsItem {
        // width: 171px;
        height: 73px;
        border-radius: 10px;
        padding: 0 8px 0 22px;
        box-sizing: border-box;
        // background: #fbebd4;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .assetsInfo {
          font-size: 15px;

          .assetsNum {
            color: #827f9d;
            margin-top: 7px;
          }
        }

        .goldenBeans {
          width: 60px;
          height: 60px;
          background: url("../../assets/png/goldenBeans.png") no-repeat;
          background-size: 100% 100%;
          // margin-top: 7px;
        }

        .couponIcon {
          width: 67px;
          height: 56px;
          background: url("../../assets/png/coupon.png") no-repeat;
          background-size: 100% 100%;
          // margin-top: 14px;
        }
      }
      .goldBg {
        background: url("../../assets/png/goldBg.png") no-repeat;
        background-size: 100% 100%;
      }
      .couponBg {
        background: url("../../assets/png/couponBg.png") no-repeat;
        background-size: 100% 100%;
      }
      // .assetsItem:last-child {
      //   background: #ffe9e0;
      //   padding: 0 0 0 10px;
      // }
    }

    .optionsBox {
      padding: 20px 12px 17px;
      box-sizing: border-box;
      background: #fff;
      margin: 12px 0 11px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-row-gap: 12px;
      grid-column-gap: 29px;
      border-radius: 10px;

      .optItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .optIcon {
          width: 30px;
          height: 30px;
          margin-bottom: 4px;
        }

        .optName {
          font-size: 14px;
          color: #000;
          // color: #9f9eb1;
        }
      }
      // .optIcon:nth-child(3) {
      //   // font-size: 12px;
      //   width: 26px !important;
      //   // height: 30px;
      // }
    }
    .cellBox {
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
      padding: 0 12px;
      box-sizing: border-box;
      margin-bottom: 18px;
    }
    .invitationCode {
      font-size: 16px;
      text-align: center;
      color: #666;
      font-weight: 500;
    }
    .officialUrl {
      font-size: 12px;
      color: #999;
      text-align: center;
    }

    .rowItem {
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      border-bottom: 1px solid #e6e6e6;

      .rowIcon {
        width: 30px;
        height: 30px;
        margin-right: 6px;
      }

      .rowName {
        flex: 2;
        font-size: 12px;
      }

      .rightArrow {
      }
    }
    .rowItem:last-child {
      border-bottom: none;
    }

    .mb80 {
      margin-bottom: 150px;
    }
  }

  .signPop {
    background: #00000000;

    .signBox {
      width: 265px;
      height: 337px;
      background: url("../../assets/png/signPop.png") center no-repeat;
      background-size: 100% 100%;
      position: relative;

      .signSuc {
        font-size: 24px;
        color: #fff;
        position: absolute;
        top: 31px;
        left: 29px;
      }

      .sginDate {
        width: 96px;
        height: 18px;
        border-radius: 18px;
        line-height: 18px;
        font-size: 10px;
        text-align: center;
        background: #fbcf2f;
        position: absolute;
        top: 67px;
        left: 29px;
      }

      .dateBox {
        position: absolute;
        top: 146px;
        left: 50%;
        width: 210px;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .dateItem {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .signedBg {
            background: #9521ff !important;
          }

          .date {
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #cdbbf5;
            border-radius: 50%;
            color: #fff;
            position: relative;

            .couponSignDate {
              width: 14.5px;
              height: 13.4px;
            }

            .signed {
              width: 18px;
              height: 18px;
            }

            .dateCoponNum {
              position: absolute;
              bottom: -14px;
              font-size: 10px;
              color: #7145e7;
            }
          }

          .line {
            width: 6px;
            height: 2px;
            background: #cdbbf5;
            margin: 0 4px;
          }
        }
      }

      .signContentBox {
        position: absolute;
        top: 189px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
      }

      .awardTitle {
        font-size: 15px;
        white-space: nowrap;
      }

      .awardBox {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #9521f9;
        font-size: 24px;

        .signAward {
          width: 52px;
          height: 52px;
        }
      }

      .signBtn {
        width: 218px;
        height: 39px;
        line-height: 39px;
        border-radius: 39px;
        font-size: 18px;
        color: #fff;
        background: #7145e7;
        text-align: center;
      }

      .signTip {
        color: #999999;
        font-size: 10px;
        margin-top: 8px;
      }
    }
  }

  .certificatePop {
    border-radius: 9px;

    .certificateBox {
      width: 307px;

      // padding: 0 18px;
      // box-sizing: border-box;
      .certificateTitle {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        margin: 18px 0;
      }

      .certificateContent {
        font-size: 12px;
        line-height: 24px;
        margin: 0 18px 18px;
      }

      .bindPhoneBox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 13px;

        .bindPhoneText {
          font-size: 15px;
          color: #999999;
        }
      }

      .popLine {
        width: 100%;
        height: 1px;
        background: #e6e6e6;
      }

      .saveBtn {
        padding: 14px 0 24px;
        box-sizing: border-box;
        font-size: 18px;
        text-align: center;
      }
    }
  }
  .aiUndress {
    height: 70px;
    width: 70px;
    background: url("./../../assets/gif/aiUndress.gif") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    right: 15px;
    bottom: 90px;
    z-index: 999;
  }
}
.msgTip {
  position: relative;
  .redDot {
    position: absolute;
    top: -8px;
    right: -20px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fc4162;
  }
}
.signIn {
  margin: 16px 0;
  width: 100%;
  height: 32px;
  font-size: 12px;
  font-weight: 500;
  .signIn_top {
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .signIn_left {
      > span {
        color: rgba(33, 33, 33, 0.6);
      }
    }
    .signIn_right {
      color: rgba(33, 33, 33, 0.6);
    }
    .ckeckBtn {
      color: #fc4162;
    }
  }
  .signIn_bottom {
    width: 100%;
    height: 15px;
    .progress {
      width: 100%;
      height: 10px;
      border-radius: 20px;
      background: #dcdcdc !important;
    }
  }
}
.fiveGrid {
  margin: 4px 0 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 6px;
  grid-column-gap: 3px;
  padding: 8px 4px;
  box-sizing: border-box;
  border-radius: 10px;
  .rectAdvBox {
    width: 60px;
    .rectAdvIcon {
      height: 60px;
      width: 60px;
      border-radius: 14.6px;
      overflow: hidden;
    }
    .rectName {
      font-size: 12px;
      color: #333;
      margin-top: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-align: center;
    }
  }
}
</style>
